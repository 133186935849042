<template>
  <div :style="widget ? {marginBottom: '46px'} : {}" class="et-viewer">
    <div
      v-if="!widget && isAuthenticated"
      :style="{width: fullScreenMode ? 'calc(100% - 130px)' : '100%'}"
      class="d-flex align-center px-2"
      style="height: 50px"
    >
      <span v-if="this.selectedIds.length">{{ $t('selected') }}: {{ this.selectedIds.length }}</span>
      <v-spacer/>
      <v-tooltip v-if="selectedIds.length && setAbility('MODERATOR')" bottom color="black">
        <template v-slot:activator="{on}">
          <v-btn class="ml-2" color="primary" outlined @click="updateSelected" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('button.edit') }}</span>
        </template>
      </v-tooltip>
      <v-tooltip v-if="selectedIds.length && setAbility('MODERATOR')" bottom color="black">
        <template v-slot:activator="{on}">
          <v-btn
            class="ml-2" color="red"
            outlined
            @click="openRemoveConfirmationDialog"
            v-on="on"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('button.remove') }}</span>
        </template>
      </v-tooltip>
      <v-tooltip bottom color="black">
        <template v-slot:activator="{on}">
          <v-btn v-show="showResetFilterButton" class="ml-2" outlined @click="resetFilter" v-on="on">
            <v-icon small>mdi-filter-remove</v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('resetFilter') }}</span>
        </template>
      </v-tooltip>
      <v-tooltip bottom color="black">
        <template v-slot:activator="{on}">
          <v-btn :disabled="!setAbility('MODERATOR')" class="ml-2" outlined @click="openEtAddRowDialog" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('addRow') }}</span>
        </template>
      </v-tooltip>
    </div>
    <v-data-table
      v-if="filteredHeaders"
      id="et-table"
      :headers="filteredHeaders"
      :items="table.body"
      :loading-text="$t('loading')"
      :no-data-text="$t('noData')"
      :no-results-text="$t('noResults')"
      :style="{height: `calc(100% - ${ isAuthenticated ? '96' : '46' }px)`}"
      disable-filtering
      disable-pagination
      disable-sort
      fixed-header
      hide-default-footer
      hide-default-header
    >
      <template v-slot:header>
        <thead>
        <tr>
          <th v-if="primaryHeader && setAbility('MODERATOR')" class="px-3" style="width: 30px">
            <v-checkbox :value="isAllItemsSelected" class="ml-2" color="primary" hide-details @change="selectAllItems"/>
          </th>
          <th v-for="header in filteredHeaders" class="pb-1 px-1" style="vertical-align: baseline">
            <div class="d-flex flex-column align-start justify-start flex-grow-1">
              <v-hover v-slot="{hover}">
                <div
                  class="et-table-header-text d-flex v-btn pointer"
                  @click="e => setDirection(header, e)"
                >
                  <v-tooltip color="black" open-delay="700" top>
                    <template v-slot:activator="{on}">
                      <div
                        class="bold ml-1 et-header-filter-item"
                        v-on="on"
                      >
                        {{ header.fieldName }}
                      </div>
                    </template>
                    {{ header.fieldName }}
                  </v-tooltip>
                  <v-icon
                    class="ml-2"
                    color="black"
                    small
                  >
                    {{ getDirectionIcon(header) }}
                  </v-icon>
                  <div v-if="sortingConfig.find(el => el.index > 1)">
                    {{ getSortingIndex(header) }}
                  </div>
                  <div :style="{visibility: hover ? 'visible' : 'hidden'}">
                    <v-icon v-if="setAbility('MODERATOR')" small @click.stop="editHeaderName(header)">
                      mdi-pencil-outline
                    </v-icon>
                  </div>
                </div>
              </v-hover>
              <div v-if="filter.length > 0" style="width: 100%">
                <component
                  :is="getFilterComponent(header)"
                  :filter="filter"
                  :getIndexOfFilter="getIndexOfFilter"
                  :header="header"
                  :tableId="et.id"
                />
              </div>
            </div>
          </th>
          <th
            v-if="layers && layers.length"
            style="width: 25px; border-left: none"
          />
        </tr>
        </thead>
      </template>
      <template v-slot:item="{ item, index }">
        <et-viewer-item
          :commentHeaders="commentHeaders"
          :headers="filteredHeaders"
          :is-selected="isItemSelected(item)"
          :item="item"
          :primary-header="primaryHeader"
          :rules="et.rules"
          @openEtiItemDialog="openEtItemDialog"
          @updateSelected="selectItem(item)"
        >
          <template v-slot:additional-column>
            <td
              v-if="layers && layers.length"
              class="map-button-td"
              style="position: sticky; right: 0; border-left: none"
            >
              <v-btn
                :ref="`map-button-${index}`"
                class="elevation-2"
                color="black"
                icon
                style="background-color: white"
                @click="e => openFeature(e, item, index)"
              >
                <v-icon small>map</v-icon>
              </v-btn>
            </td>
          </template>
        </et-viewer-item>
      </template>
    </v-data-table>
    <d-pagination
      :items-per-page-list="etConst.PAGINATION_ITEMS_PER_PAGE"
      :pagination="pagination"
      :widget="widget"
      class="d-pagination"
      @openPage="openPage"
    />
    <et-edit-query-dialog
      v-show="setAbility('SYSTEM_ADMIN')"
      ref="etEditSelectDialog"
      @save="onEditQuery"
    />
    <et-item-dialog
      ref="etItemDialog"
      @tableUpdated="updateBody"
      @updated="updateBody"
    />
    <et-edit-item-dialog
      ref="etEditItemDialog"
      @updated="updateBody"
    />
    <et-view-settings-dialog
      ref="etViewSettingsDialog"
      @save="onEditQuery"
    />
    <layer-poi-dialog
      ref="layerPoiDialog"
      actions-toolbar
      map-only-mode
      map-widget
    />
    <wms-feature-dialog
      ref="wmsFeatureDialog"
    />
    <et-import-dialog
      ref="etImportDialog"
      @updateTable="updateBody"
    />
    <et-create-dialog
      ref="etCreateDialog"
      @save="onSaveCopy"
    />
    <et-add-row-dialog
      ref="etAddRowDialog"
      @save="updateBody"
    />
    <et-to-layer-dialog
      ref="etToLayerDlg"
      :external-table="et"
    />
    <features-by-et-item-dialog
      ref="featuresByEtItemDialog"
    />
    <header-name-editor
      ref="headerNameEditor"
      @updated="updateBody"
    />
    <et-aggregation-dialog
      ref="aggregationsDialog"
      :filter="filter"
      @updated="$emit('etUpdated')"
    />
    <confirmation-dialog ref="removeRowsConfirmation" :title="$t('removeConfirmation')" @confirm="removeSelected"/>
  </div>
</template>
<script>

import EtEditQueryDialog from '@/components/et/EtEditQueryDialog.vue'
import { EventBus } from '@/event-bus'
import DPagination from '@/components/utils/DPagination.vue'
import messages from '@/componet-locale/et/messages'
import EtItemDialog from '@/components/et/EtItemDialog.vue'
import {
  etConst,
  getDirection,
  getDirectionIcon,
  getFilterFromHeaders,
  getSortingConfigFromHeaders
} from '@/components/et/utils/utlis'
import StringHeaderFilter from '@/components/et/header-filters/StringHeaderFilter.vue'
import BooleanHeaderFilter from '@/components/et/header-filters/BooleanHeaderFilter.vue'
import ListHeaderFilter from '@/components/et/header-filters/ListHeaderFilter.vue'
import EtViewSettingsDialog from '@/components/et/view-settings/EtViewSettingsDialog.vue'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog.vue'
import EtImportDialog from '@/components/et/EtImportDialog.vue'
import AbstractDataDetails from '@/components/utils/AbstractDataDetails.vue'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog.vue'
import EtCreateDialog from '@/components/et/EtCreateDialog.vue'
import EtAddRowDialog from '@/components/et/EtAddRowDialog.vue'
import DefaultHeaderFilter from '@/components/et/header-filters/DefaultHeaderFilter.vue'
import DateHeaderFilter from '@/components/et/header-filters/DateHeaderFilter.vue'
import moment from 'moment'
import EtToLayerDialog from '@/components/et/EtToLayerDialog.vue'
import WmsFeatureDialog from '@/components/map/geoserver/WmsFeatureDialog.vue'
import FeaturesByEtItemDialog from '@/components/et/utils/FeaturesByEtItemDialog.vue'
import EtViewerItem from '@/components/et/EtViewerItem.vue'
import HeaderNameEditor from '@/components/et/utils/HeaderNameEditor.vue'
import { etServiceMixin } from '@/mixins/et-service-mixin'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import EtAggregationDialog from '@/components/et/aggregations/EtAggregationDialog.vue'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import NumberHeaderFilter from '@/components/et/header-filters/NumberHeaderFilter.vue'
import EtEditItemDialog from '@/components/et/EtEditItemDialog.vue'

export default {
  name: 'EtViewer',
  components: {
    EtAggregationDialog,
    ConfirmationDialog,
    HeaderNameEditor,
    FeaturesByEtItemDialog,
    WmsFeatureDialog,
    EtToLayerDialog,
    EtCreateDialog,
    AbstractDataDetailsDialog,
    AbstractDataDetails,
    EtImportDialog,
    LayerPoiDialog,
    EtViewSettingsDialog,
    EtItemDialog,
    DPagination,
    EtEditQueryDialog,
    EtAddRowDialog,
    EtViewerItem,
    EtEditItemDialog
  },
  mixins: [etServiceMixin],
  i18n: { messages },
  props: {
    et: Object,
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    widget: {
      type: Boolean,
      default: false
    },
    fullScreenMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    table: Object,
    pagination: { ...etConst.DEFAULT_PAGINATION },
    sortingConfig: [],
    filter: [],
    primaryTableName: null,
    mainPredicate: 'AND',
    layers: [],
    selectedIds: [],
    updateBodyCancelSource: null
  }),
  mounted () {
    EventBus.$on('openEtEditQueryDialog', this.openEtEditQueryDialog)
    EventBus.$on('openEtViewSettingsDialog', this.openEtRulesDialog)
    EventBus.$on('openEtImportDialog', this.openEtImportDialog)
    EventBus.$on('openEtToLayerDlg', this.openEtToLayerDlg)
    EventBus.$on('exportEt', this.exportXlsx)
    EventBus.$on('makeCopy', this.makeCopy)
    EventBus.$on('applyEtFilterByFeatureIds', this.changeFilterByFeatureIds)
    this.init()
  },
  methods: {
    init () {
      this.filter = []
      this.sortingConfig = []
      this.pagination = { ...etConst.DEFAULT_PAGINATION }
      this.mainPredicate = 'AND'
      this.getRelatedLayers()
      this.updateBody()
      this.resetFilter()
    },
    updateBody () {
      this.selectedIds = []
      const validPage = this.getValidPage()
      const sortingConfig = getSortingConfigFromHeaders(this.sortingConfig)
      if (this.updateBodyCancelSource) {
        this.updateBodyCancelSource.cancel('Canceled')
      }
      this.updateBodyCancelSource = this.$axios.CancelToken.source()
      this.$axios
        .post(this.isAuthenticated ? 'et/query/select' : 'public-data/et-select', {
          tableId: this.et.id,
          pageable: {
            page: validPage,
            rowPerPage: this.pagination.itemsPerPage
          },
          mainPredicate: this.mainPredicate,
          sortingConfig: sortingConfig,
          conditions: this.filter
        }, { cancelToken: this.updateBodyCancelSource.token })
        .then(res => {
          this.table = {
            headers: res.data.headers,
            body: res.data.body
          }
          this.pagination.itemsCount = res.data.itemsCount
          this.pagination.page = validPage
        })
        .catch(e => {
          if (e) {
            EventBus.$emit('showErrorMessage', this.$t(e.data.message || 'error'))
          }
        })
    },
    exportXlsx () {
      const validPage = this.getValidPage()
      const sortingConfig = getSortingConfigFromHeaders(this.sortingConfig)
      this.$axios
        .post('et/export/xlsx', {
            tableId: this.et.id,
            pageable: {
              page: validPage,
              rowPerPage: this.pagination.itemsPerPage
            },
            mainPredicate: this.mainPredicate,
            sortingConfig: sortingConfig,
            conditions: this.filter
          },
          {
            responseType: 'arraybuffer'
          })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          const name = this.et.name + ' ' + new Date().toLocaleDateString() || 'external-table'
          link.download = `${name}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportError')))
    },
    async openFeature (e, item, index) {
      const button = this.$refs[`map-button-${index}`]
      button.loading = true
      e.stopPropagation()

      const items = []

      for (let layer of this.layers) {
        if (layer.layer.type === 'LAYER_POI') {
          const value = item[layer.header.alias]
          if (!value && typeof (value) !== 'number') continue
          const body = {
            page: 0,
            rowsPerPage: 15,
            layerPoiCriteria: {
              fieldId: layer.lpSearchField.id,
              dataType: layer.lpSearchField.type,
              value
            }
          }
          await this.$axios
            .post('layer-poi/filter', body, {
              params: {
                layerId: layer.layer.id,
                templateId: layer.lpTemplate.id
              }
            })
            .then(res => res.data.content)
            .then(features => {
              if (features.length > 0) items.push({ layer: layer.layer, features })
            })
        }

        if (layer.layer.type === 'WMS') {
          await this.$axios.get(window.location.origin + '/geoserver/wfs', {
            params: {
              service: 'wfs',
              version: '2.0.0',
              request: 'GetFeature',
              outputFormat: 'application/json',
              typeName: layer.layer.layerId,
              cql_filter: `${layer.wfsPropertyName}='${item[layer.header.alias]}'`
            }
          })
            .then(res => {
              const features = res.data.features.map(el => ({ ...el, layer: layer.layer }))
              if (features.length > 0) items.push({ layer: layer.layer, features })
            })
        }
        if (layer.layer.type === 'ZWS') {
          await ZwsCommandBuilder.getLayerProps(layer.layer)
          const res = await ZwsCommandBuilder.search(layer.layer, item[layer.header.alias], true)
          if (res.length > 0) items.push({ layer: layer.layer, features: res.map(el => el.row) })
        }
      }

      await this.$refs.featuresByEtItemDialog.open(items)
      button.loading = false
    },
    makeCopy () {
      if (!this.$refs.etCreateDialog) return
      let item = JSON.parse(JSON.stringify(this.et))
      item = {
        provider: item.provider,
        description: item.description,
        status: item.status,
        keywords: item.keywords,
        categoryList: item.categoryList,
        qfrom: item.qfrom,
        qwhere: item.qwhere,
        qgroupby: item.qgroupby,
        qorderby: item.qorderby,
        primaryTableName: item.primaryTableName,
        headers: item.headers.sort((prev, next) => prev.showIndex - next.showIndex)
      }
      item.headers.forEach(el => delete el.id)
      this.$refs.etCreateDialog.open(item)
    },
    onSaveCopy () {
      EventBus.$emit('etCopySaved')
      this.$router.back()
    },
    getFilterComponent (header) {
      switch (header.fieldType) {
        case 'BOOLEAN':
          return BooleanHeaderFilter
        case 'LIST':
          return ListHeaderFilter
        case 'DATE':
          return DateHeaderFilter
        case 'STRING':
          return StringHeaderFilter
        case 'NUMBER':
          return NumberHeaderFilter
        default:
          return DefaultHeaderFilter
      }
    },
    onEditQuery () {
      this.$emit('etUpdated')
    },
    openPage (page) {
      this.pagination.page = page
    },
    openEtItemDialog (item) {
      this.$refs.etItemDialog.open(this.et, item)
    },
    openEtEditQueryDialog () {
      if (this.$refs.etEditSelectDialog) {
        this.$refs.etEditSelectDialog.open(this.et)
      }
    },
    openEtRulesDialog () {
      if (this.$refs.etViewSettingsDialog) {
        this.$refs.etViewSettingsDialog.open(this.et.id)
      }
    },
    openEtImportDialog () {
      if (this.$refs.etImportDialog) {
        this.$refs.etImportDialog.open(this.et)
      }
    },
    openEtToLayerDlg () {
      if (this.$refs.etToLayerDlg) {
        this.$refs.etToLayerDlg.open()
      }
    },
    getIndexOfFilter (alias) {
      if (this.filter) {
        return this.filter.indexOf(this.filter.find(el => el.alias === alias))
      }
    },
    getValidPage () {
      const pageCount = Math.ceil(this.pagination.itemsCount / this.pagination.itemsPerPage)
      if (this.pagination.page > pageCount) {
        return pageCount === 0 ? 1 : pageCount
      }
      return this.pagination.page
    },
    getDirectionIcon (header) {
      return this.sortingConfig.find(el => el.key === header.key)
        ? getDirectionIcon(this.sortingConfig.find(el => el.key === header.key).direction)
        : ''
    },
    getSortingIndex (header) {
      return this.sortingConfig.find(el => el.key === header.key && el.index > 0)
        ? this.sortingConfig.find(el => el.key === header.key).index
        : ''
    },
    setDirection (header, e) {
      if (!e.shiftKey) {
        this.sortingConfig.forEach((el) => {
          if (el.key === header.key) {
            el.direction = getDirection(el.direction)
            el.index = 1
          } else {
            el.direction = 'NULL'
            el.index = 0
          }
        })
      } else {
        this.sortingConfig.forEach((el) => {
          if (el.key === header.key) {
            el.direction = getDirection(el.direction)
          }
          if (el.key === header.key && el.index === 0) {
            el.index = Math.max(...this.sortingConfig.map(el => el.index)) + 1
          }
        })
      }
    },
    changeFilterByFeatureIds (applyFilterByFeatureIdsDto) {
      applyFilterByFeatureIdsDto.forEach(layerToFeatures => {
        const layer = this.layers.find(l => l.layer.id === layerToFeatures.layerId)
        const header = layer.header
        this.filter[this.getIndexOfFilter(header.alias)].value = layerToFeatures.featureIds
        this.filter[this.getIndexOfFilter(header.alias)].selectAll = false
        this.filter[this.getIndexOfFilter(header.alias)].includeEmpty = false
      })
    },
    openEtAddRowDialog () {
      this.$refs.etAddRowDialog.open(this.et.id)
    },
    getRelatedLayers () {
      if (!this.isAuthenticated) return
      this.$axios
        .get('/et/get-related-layers', {
          params: {
            etId: this.et.id
          }
        })
        .then(response => {
          this.layers = response.data
        })
    },
    resetFilter () {
      this.sortingConfig = getSortingConfigFromHeaders(this.et.headers)
      this.filter = getFilterFromHeaders(this.et.headers)
    },
    reloadTable () {
      this.table = {}
      this.updateBody()
    },
    editHeaderName (header) {
      this.$refs.headerNameEditor.open(header)
    },
    isItemSelected (item) {
      if (!this.primaryHeader) return false
      return this.selectedIds.includes(item[this.primaryHeader.alias])
    },
    selectItem (item) {
      if (!this.primaryHeader) return EventBus.$emit('showErrorMessage', this.$t('error'))
      if (this.isItemSelected(item)) {
        this.selectedIds.splice(this.selectedIds.indexOf(item[this.primaryHeader.alias]), 1)
      } else {
        this.selectedIds.push(item[this.primaryHeader.alias])
      }
    },
    selectAllItems () {
      if (!this.primaryHeader) return EventBus.$emit('showErrorMessage', this.$t('error'))
      if (this.selectedIds.length < this.table.body.length) {
        this.selectedIds = this.table.body.map(it => it[this.primaryHeader.alias])
      } else {
        this.selectedIds = []
      }
    },
    updateSelected () {
      this.$refs.etEditItemDialog.open(this.et, this.selectedIds)
    },
    openRemoveConfirmationDialog () {
      this.$refs.removeRowsConfirmation.open({ name: this.selectedIds.length })
    },
    removeSelected () {
      this.deleteRow(this.et, this.selectedIds)
        .then(() => {
          this.selectedIds = []
          this.updateBody()
        })
    },
    openEtAggregationDialog () {
      this.$refs.aggregationsDialog.open(this.et)
    },
    updateAutocompletes () {
      EventBus.$emit('updateAutocompletes')
    }
  },
  computed: {
    etConst () {
      return etConst
    },
    filteredHeaders () {
      if (!this.table.headers) return []
      return this.table.headers
        .filter(el => !el.excludeFromTable && !el.isComment)
        .sort((prev, next) => (prev.showIndex - next.showIndex))
    },
    commentHeaders () {
      return this.table.headers
        .filter(el => el.isComment)
    },
    moment () {
      return moment
    },
    showResetFilterButton () {
      return this.sortingConfig.some(el => el.direction !== 'NULL')
        || this.filter.some(el => el.value.length && el.value[0] || el.fieldValue || !el.selectAll || !el.includeEmpty)
    },
    primaryHeader () {
      return this.table && this.table.headers && this.table.headers.find(el => el.isPrimaryTableKey)
    },
    isAllItemsSelected () {
      return this.selectedIds.length === this.table.body.length && this.selectedIds.length !== 0
    }
  },
  watch: {
    et: {
      handler () {
        this.init()
      },
      deep: true
    },
    'pagination.itemsPerPage' () {
      this.updateBody()
    },
    'pagination.page' () {
      this.updateBody()
    },
    sortingConfig: {
      handler () {
        this.updateBody()
      },
      deep: true
    },
    filter: {
      handler () {
        this.updateBody()
        this.$nextTick(() => this.updateAutocompletes())
      },
      deep: true
    },
    mainPredicate () {
      this.updateBody()
    }
  }
}
</script>

<style>

.et-viewer {
  height: 100%;
  position: relative;
}

#et-table {
  overflow: auto;
  scrollbar-gutter: stable;
}

#et-table tbody > tr > td:not(:first-child),
#et-table thead > tr > th:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}

#et-table th {
  font-weight: normal;
}

#et-table .v-data-table__wrapper {
  height: 100%;
  overflow-y: scroll;
}

.et-table-header-text {
  flex-wrap: nowrap;
  width: fit-content;
  height: 24px;
}

.et-viewer .d-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.et-header-filter-item {
  color: black;
  min-width: 100px;
  max-width: 250px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

</style>
