<template>
  <div>
    <vl-map
      v-if="map"
      id="map"
      ref="map"
      :data-projection="projection"
      :renderer="view.renderer"
      class="map-for-export"
      load-tiles-while-animating
      load-tiles-while-interacting
      @created="mapCreated"
    >
      <vl-view
        ref="view"
        :center.sync="view.center"
        :extent="view.extent"
        :resolution.sync="resolution"
        :zoom.sync="view.zoom"
      />

      <vl-layer-group
        ref="layerGroup"
        :extent="transform(view.extent)"
      >
        <component
          :is="getLayerComponent(layer)"
          v-for="(layer, index) in visibleLayers"
          :ref="'layer-'+layer.id"
          :extent="view.extent"
          :layer="layer"
          :resolution="resolution"
          :z-index="index"
        />
      </vl-layer-group>

      <portal-target name="simulation-layer"/>
    </vl-map>
  </div>
</template>

<script>
import ZwsLayer from '@/components/map/layer/ZwsLayer.vue'
import WmsLayer from '@/components/map/layer/WmsLayer.vue'
import ObjectLayer from '@/components/map/layer/ObjectLayer.vue'
import MapContextMenu from '@/components/map/helpers/MapContextMenu'
import { transformExtent } from 'ol/proj'
import Measurer from '@/components/map/helpers/measurer/Measurer'
import MapIssue from '@/components/map/MapIssue'
import XyzLayer from '@/components/map/layer/XyzLayer.vue'
import { ScaleLine } from 'ol/control'
import UserGeoloc from "@/components/map/UserGeoloc.vue"
import PkkLayer from '@/components/map/layer/PkkLayer.vue'
import WmtsLayer from '@/components/map/layer/WmtsLayer.vue'
import VectorTileLayer from '@/components/map/layer/VectorTileLayer.vue'

export default {
  name: 'MapForExport',
  components: {
    UserGeoloc,
    XyzLayer,
    MapIssue,
    Measurer,
    MapContextMenu,
    ObjectLayer,
    ZwsLayer,
    WmsLayer,
    PkkLayer,
    WmtsLayer,
    VectorTileLayer
  },
  props: {
    map: Object,
    initialView: Object
  },
  data () {
    return {
      projection: 'EPSG:4326',
      resolution: undefined,
      view: {
        zoom: 1,
        center: [0, 0],
        renderer: 'canvas',
        extent: undefined
      },
      layers: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    transform: (extent) => {
      if (!extent) return undefined
      return transformExtent(extent, 'EPSG:4326', 'EPSG:3857')
    },
    initLayers () {
      this.layers.forEach(it => {
        if (it.type === 'LAYER_POI') {
          it.zoomLevel = 10
        } else {
          it.zoomLevel = 0
        }
      })
      if (this.map) {
        if (this.map.layer) {
          this.layers = [this.map.layer]
        } else {
          let layerConfigList
          if (this.map.parentMap) {
            layerConfigList = this.map.parentMap.layerConfigList
          } else {
            layerConfigList = this.map.layerConfigList
          }
          this.layers = layerConfigList
            .sort((a, b) => {
              return a.indexNumber > b.indexNumber ? 1 : -1
            })
            .map(it => {
              let layer = it.layer
              layer.visible = it.visible
              layer.indexNumber = it.indexNumber
              return layer
            })
        }
      }
    },
    init () {
      this.initLayers()
    },
    mapCreated (vm) {
      this.$refs.map.$map.addControl(new ScaleLine())
    },
    getLayerComponent (layer) {
      switch (layer.type) {
        case 'ZWS':
          return 'zws-layer'
        case 'WMS':
          return 'wms-layer'
        case 'LAYER_POI' :
          return 'object-layer'
        case 'XYZ' :
          return 'xyz-layer'
        case 'WMTS':
          return 'wmts-layer'
        case 'VECTOR_TILE':
          return 'vector-tile-layer'
        case 'PKK':
          return 'PkkLayer'
      }
    },
    setView (view) {
      this.view = { ...view }
    }
  },
  computed: {
    visibleLayers () {
      return this.layers.filter(it => it.visible)
    },
    allZwsLayers () {
      return this.layers.filter(layer => layer.type === 'ZWS')
    },
    zwsLayers () {
      return this.visibleLayers.filter(layer => layer.type === 'ZWS')
    },
    wmsLayers () {
      return this.visibleLayers.filter(layer => layer.type === 'WMS')
    },
    objectLayers () {
      return this.visibleLayers.filter(layer => layer.type === 'LAYER_POI')
    }
  }
}
</script>

<style scoped>
.map-for-export {
  width: 100%;
  height: 315px;
}
</style>
