<template>
  <div>
    <et-item
      v-for="header in filteredHeaders"
      :key="header.id"
      v-model:value="item[header.alias]"
      :editable="editable"
      :hasChanged="isItemChanged(header)"
      :header="header"
      :hideReadOnly="hideReadOnly"
      :relations="getRelations(header)"
      :setNullMode="setNullMode"
      :shortMode="shortMode"
      :table-id="et.id"
    />
    <div v-for="commented in commentedHeaders">
      <div class="pt-2 pl-2 mb-2" style="border-left: 2px solid #7ab1f4; border-radius: 10px">
        <et-item
          v-for="header in commented"
          :key="header.id"
          v-model:value="item[header.alias]"
          :editable="editable"
          :hasChanged="isItemChanged(header)"
          :header="header"
          :hideReadOnly="hideReadOnly"
          :relations="getRelations(header)"
          :setNullMode="setNullMode"
          :shortMode="shortMode"
          :table-id="et.id"
        />
      </div>
    </div>
  </div>
</template>
<script>

import EtItem from '@/components/et/utils/EtItem.vue'

export default {
  name: 'EtItemCard',
  props: {
    et: Object,
    item: Object,
    initialItem: { Object },
    shortMode: Boolean,
    setNullMode: Boolean,
    hideReadOnly: Boolean,
  },
  components: { EtItem },
  methods: {
    getRelations (header) {
      if (this.et.relations) return this.et.relations.filter(relation => relation.mainHeader.id === header.id)
    },
    isItemChanged (header) {
      const prevValue = this.initialItem[header.alias]
      const curValue = this.item[header.alias]
      return (!prevValue && !!curValue)
        || (!!prevValue && !curValue)
        || (prevValue === undefined && curValue !== undefined)
        || (!!prevValue && !header.updateFromParentTable && prevValue !== curValue)
    }
  },
  computed: {
    filteredHeaders () {
      return this.et.headers
        .filter(el => !el.isComment
          && !this.et.headers.some(h => h.isComment && h.commentField && h.commentField.id === el.id)
        )
        .sort((prev, next) => prev.showIndex - next.showIndex)
    },
    commentedHeaders () {
      const commentedHeaders = this.et.headers.filter(el => !el.isComment
        && this.et.headers.some(h => h.isComment && h.commentField && h.commentField.id === el.id)
      )
      return commentedHeaders.map(el => ([
        el, ...this.et.headers.filter(it => it.isComment && it.commentField.id === el.id)
      ]))
    },
    primaryHeader () {
      return this.et.headers.find(el => el.isPrimaryTableKey)
    },
    editable () {
      return !!this.primaryHeader ||
        !!this.et.primaryTableName
    }
  }
}
</script>
